import * as Comlink from 'comlink';
import Worker from 'worker-loader!./imageUtil.worker';
import Jimp from 'jimp';
import Ajv, { DefinedError } from 'ajv';
import { ArtistMetadata, ImageMetadata } from '../data-types';
import {
    Blur,
    Brightness,
    Circle,
    HorizontalAlignment,
    Opacity,
    Operation,
    OperationType,
    Preset,
    Resize,
    ResizeStyle,
    Rotate,
    Schemas,
    UnitType,
    VerticalAlignment
} from '@amzn/forge-image-processing-types';

/**
 * Returns the RAW image contained in the provided artist metadata. If unavailable,
 * the image with the largest dimensions is returned instead. If there are no images
 * associated with the artist metadata, it returns null.
 */
export function findLargestImage(metadata: ArtistMetadata): ImageMetadata | null {
    const images = metadata.images || [];
    // First try loading the RAW image if there is one
    const rawIndex = images.findIndex((img) => img.size.toLowerCase() == 'raw');
    if (rawIndex >= 0) {
        return images[rawIndex];
    }
    // Otherwise look at the dimensions of each image
    let maxSize = 0,
        maxIndex = -1;
    images.forEach((image, idx) => {
        const minSide = Math.min(image.heightPx, image.widthPx);
        if (minSide > maxSize) {
            maxIndex = idx;
            maxSize = minSide;
        }
    });
    return maxIndex >= 0 ? images[maxIndex] : null;
}

const draftOptions = { allErrors: true, coerceTypes: true };
const finalOptions = { ...draftOptions, removeAdditional: true };

const validateDraft = new Ajv(draftOptions).compile(Schemas.Preset);
const validateFinal = new Ajv(finalOptions).compile(Schemas.Preset);

export function validatePreset(preset: Preset, draft: boolean): DefinedError[] {
    const valid = draft ? validateDraft(preset) : validateFinal(preset);
    const errors = valid ? [] : draft ? validateDraft.errors : validateFinal.errors;
    return errors as DefinedError[];
}

const DefaultBlur: Blur = {
    type: OperationType.Blur,
    blur: 10,
};

const DefaultBrightness: Brightness = {
    type: OperationType.Brightness,
    brightness: 0,
};

const DefaultCircle: Circle = {
    type: OperationType.Circle,
    units: UnitType.Percentage,
    radius: 50,
    offsetX: 50,
    offsetY: 50,
};

const DefaultOpacity: Opacity = {
    type: OperationType.Opacity,
    opacity: 100,
};

const DefaultResize: Resize = {
    type: OperationType.Resize,
    style: ResizeStyle.Cover,
    height: 1100,
    width: 1100,
    vertical: VerticalAlignment.Middle,
    horizontal: HorizontalAlignment.Center,
};

const DefaultRotate: Rotate = {
    type: OperationType.Rotate,
    degrees: 180,
    resize: false,
};

export type SupportedOperationType = Exclude<OperationType, OperationType.Print>;

export const DefaultOperations: Record<SupportedOperationType, Operation> = {
    [OperationType.Blur]: DefaultBlur,
    [OperationType.Brightness]: DefaultBrightness,
    [OperationType.Circle]: DefaultCircle,
    [OperationType.Opacity]: DefaultOpacity,
    [OperationType.Resize]: DefaultResize,
    [OperationType.Rotate]: DefaultRotate,
};

export const OperationName: Record<SupportedOperationType, string> = {
    [OperationType.Blur]: 'Blur',
    [OperationType.Brightness]: 'Brightness',
    [OperationType.Circle]: 'Circle',
    [OperationType.Opacity]: 'Opacity',
    [OperationType.Resize]: 'Resize',
    [OperationType.Rotate]: 'Rotate',
};

export const OperationDescription: Record<SupportedOperationType, string> = {
    [OperationType.Blur]: 'Blurs the image by the given number of pixels',
    [OperationType.Brightness]: 'Adjust the brightness from -100% (black) to 100% (white)',
    [OperationType.Circle]: 'Crops the image with a circle',
    [OperationType.Opacity]: 'Multiply the alpha channel of each pixel by a factor of 0 - 100%',
    [OperationType.Resize]: 'Changes the dimensions of the image',
    [OperationType.Rotate]: 'Rotates the image clockwise by a number of degrees',
};

interface ImageWorker {
    formatImage(data: Buffer, preset: Preset): Promise<Buffer>;
}

const worker: ImageWorker = Comlink.wrap(new Worker());

/**
 * Returns a copy of the supplied image after applying the given format options.
 * This process is performed asynchronously in a background worker thread as it can take
 * several seconds to complete.
 */
export async function formatImage(data: Buffer, preset: Preset): Promise<Jimp> {
    const result = await worker.formatImage(data, preset);
    return Jimp.read(Buffer.from(result));
}
