import { Button } from '@amzn/awsui-components-react';
import React from 'react';
import { ChangeEvent } from 'react';

interface Props extends Button.Props {
    accept: string;
    multiple: boolean;
    onFileChange: (files: File[]) => void;
}

export class UploadButton extends React.Component<Props> {
    buttonRef = React.createRef<HTMLInputElement>();

    onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const files: File[] = [];
        if (e.target.files) {
            for (let i = 0; i < e.target.files?.length; i++) {
                files.push(e.target.files[i]);
            }
        }
        this.props.onFileChange(files);
    };

    render(): JSX.Element {
        return (
            <>
                <Button {...this.props} onClick={() => this.buttonRef.current?.click()}>
                    {this.props.children}
                </Button>
                <input
                    className="awsui-util-hide"
                    type="file"
                    multiple={this.props.multiple}
                    formEncType="multipart/form-data"
                    ref={this.buttonRef}
                    accept={this.props.accept}
                    onChange={this.onFileChange}
                />
            </>
        );
    }
}
