import { DateTime } from 'luxon';
import _ from 'lodash';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';
const format = `${DATE_FORMAT} ${TIME_FORMAT}`;
const timeOpts = { zone: 'utc' };

export function convertToDateTimeString(date?: string, time?: string): string | undefined {
    return getDateTimeFromStrings(date, time)?.toISO();
}

export function convertToTimeString(time?: string): string | undefined {
    if (time) {
        const dateTime = DateTime.fromISO(time, timeOpts);
        return dateTime.isValid ? dateTime.toUTC().toISOTime({ suppressSeconds: true }) : undefined;
    }
    return undefined;
}

/** For a given ISO string, return a DateTime instance */
export function getDateTimeFromString(dateTimeString?: string): DateTime | undefined {
    if (dateTimeString) {
        const dateTime = DateTime.fromISO(dateTimeString);
        return dateTime.isValid ? dateTime.toUTC() : undefined;
    }
    return undefined;
}

/** For given date and time strings in the formats defined above, return the date time instance */
export function getDateTimeFromStrings(date?: string, time?: string): DateTime | undefined {
    if (date && time) {
        const dateTime = DateTime.fromFormat(`${date} ${time}`, format, timeOpts);
        return dateTime.isValid ? dateTime.toUTC() : undefined;
    }
    return undefined;
}
