import { PresignedUrl } from "../data-types";
import { fetchGetPresignedS3Url } from "./fetchUtil";
import { prepareFormData } from "./jsonUtil";

export interface UploadResult {
    url: string;
    filename: string;
    extension: string;
}

export function uploadMedia(file?: File): Promise<UploadResult> {
    if (!file) {
        return Promise.reject('Cannot upload without selecting a file first.');
    }
    const extension = file.name.split('.').pop() || '';
    let presignedUrl: PresignedUrl;
    return fetchGetPresignedS3Url()
        .then((result) => {
            presignedUrl = result;
            const formData = prepareFormData(presignedUrl);
            formData.append('file', file as Blob);
            return fetch(presignedUrl.url, { method: 'POST', body: formData });
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to upload asset file: ' + response.statusText);
            }
            return {
                url: presignedUrl.url,
                filename: presignedUrl.file_name,
                extension: extension,
            };
        });
}

