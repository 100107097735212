import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ensureAuthenticated } from './auth/AuthGenerator';
import './styles/index.scss';

ensureAuthenticated().then(() => {
    ReactDOM.render(
        <div className="awsui">
            <Router>
                <App />
            </Router>
        </div>,
        document.getElementById('root'),
    );
});
